.App {
  // text-align: center;
  background-color: #002F6D;
  // border-radius: 20px;
  padding: 20px;
  // margin: 10px;
  font: "Inter";
  color: white;
  min-height: 300px;
}

.user-name {
  font-weight: bold;
  display: flex;
  justify-content: right;
  margin-bottom: 10px;
}

.user-settings {
  display: flex;
  justify-content: space-between;
}

.log-out {
  color: white;
  border:  1px solid white;
  border-radius: 5px;
  background: none;
  cursor: pointer;
}

.go-to-project-button, .check-work-button {
  color: white;
  font-size: 1.3em;
  width: 200px;
  cursor: pointer;

  &.go-to-project-button {
    background: #898B8E;
  }

  &.check-work-button {
    background: #FF5200;
    text-align: center;

    .spinner {
      // border: 4px solid rgba(0, 0, 0, 0.1);
      width: 16px;
      height: 16px;
      // border-radius: 50%;
      border-left-color: #09f;
      animation: spinner 1s linear infinite;
    }    
  }
}

.cert-title {
    // border-bottom: 2px solid white;
    font-size: 2em;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 20px;
    // padding-bottom: 10px;
    // text-decoration: underline;
}

.cert-description {
  font-size: 1.2em;
  // text-decoration: italic;
  font-style: italic;
  margin-bottom: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* STEPS CSS */
.main-container {
  /* margin: 0 auto; */
}

.steps-container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  // margin: 0 10%;
//   outline: 2px solid orange;
}

.step-number {
  font-weight: bold;
  margin-right: 10px;
}

.step-description {
  /* flex-grow: 1; */
  font-size: 0.7em;
  margin: 0 10px;
  text-align: left;
}

.step-indicator {
    font-weight: bold;
    font-size: 0.7em;
    // margin-left: 10px;
    // opacity: 0;
    // transition: opacity 0.5s ease-in;
    // transition-delay: var(--delay, 0s);
    // border: 2px solid white;
    padding: 5px 10px;
    border-radius: 40px;
}

.buttons-container {
    margin: 10px 0;
    // outline: 2px solid red;
    text-align: center;
    
    button {
        font-weight: bold;
        border: none;
        margin: 0 10px;
        padding: 10px;
        border-radius: 10px;
    }
}

.step-status {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 10px;
//   outline: 2px solid red;
  // width: 500px;
  color: white;
  font-size: 1.5em;  
}

.correct, .incorrect {
  opacity: 1;
}

.correct {
  // color: green;
  background-color: #005C5E;
}

.incorrect {
  // color: red;
  background-color: #A12B2F;
}

.spinner-container {
    display: flex;
    justify-content: center;
    // outline: 2px solid orange;
    margin: 10px;
}

.reset-exam-button {
  background-color: red;
  color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.auth-container {
  // outline: 2px solid red;
  display: flex;
  justify-content: center;

  .login-button {
    background: #f58220;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 16px;
    border-radius: 4px;
    padding: 12px 24px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    outline: none;
  }

  .auth-code-form {
    label {
      margin-right: 10px;
      font-weight: bold;
    }
    
    .auth-code-submit-button {
      margin-left: 10px;
      background: #f58220;
      color: #fff;
      border: none;
      font-weight: bold;
      font-size: 12px;
      border-radius: 4px;
      padding: 5px;
      cursor: pointer;    
    }
  }
}